<template>
  <div class="head">
    <div class="breadcrumbs">
      <span style="margin-right: 10px">
        <a-button type="primary" @click="goCharm" class="btn">浏览魅力东疆</a-button>
      </span>
    </div>
    <div class="form">
      <div class="title">天津东疆保税港区海域垃圾智能检测系统</div>
      <div class="entry">
        <a-form :model="formInline" @submit="handleSubmit">
          <a-form-item>
            <a-input
              v-model:value="formInline.user"
              placeholder="用户名"
              autocomplete="off"
              class="black"
            >
              <template #prefix
                ><UserOutlined style="color: rgb(0, 0, 1)"
              /></template>
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-input
              v-model:value="formInline.password"
              type="password"
              placeholder="密码"
              autocomplete="off"
              class="black"
            >
              <template #prefix>
                <LockOutlined style="color: rgb(0, 0, 0)" />
              </template>
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-button
              type="primary"
              html-type="submit"
              :disabled="formInline.user === '' || formInline.password === ''"
              class="black"
            >
              登录
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from 'vue'
  import { UserOutlined, LockOutlined } from '@ant-design/icons-vue'
  import { message } from 'ant-design-vue'
  export default defineComponent({
    name: 'Login',
    components: {
      UserOutlined,
      LockOutlined
    },
    data() {
      return {
        formInline: {
          user: '',
          password: ''
        },
        isMobile: window.IS_MOBILE
      }
    },
    methods: {
      //登录接口
      handleSubmit() {
        let api = 'http://39.100.158.75:8080/user/login'
        this.$axios({
          url: api,
          method: 'post',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: {
            userName: this.formInline.user,
            password: this.formInline.password
          }
        }).then(response => {
            if (response.data.success === true) {
              this.$storage.set('userinfo', response.data)
              let userinfo = this.$storage.get('userinfo')
              if (userinfo.data.role === 0) {
                //管理员
                this.$router.push({
                  path: window.IS_MOBILE ? '/home' : '/birdSeeEasternTerritory'
                })
              } else if (userinfo.data.role === 3) {
                this.$router
                  .push({
                    path: window.IS_MOBILE ? '/home' : '/wisdomCruiseBay'
                  })
              } else if (userinfo.data.role === 1 || userinfo.data.role === 2) {
                //普通工作人员
                this.$router.push({
                  path: window.IS_MOBILE ? '/home' :'/taskManage'
                })
              }
            } else {
              message.error(response.data.message)
            }
          })
      },
      //进入魅力东疆
      goCharm() {
        if (window.IS_MOBILE) {
          this.$router.push({
            path: '/touristCharmingEastMobile'
          });
        } else {
          this.$router.push({
            path: '/touristCharmingEastern'
          });
        }
      }
    }
  })
</script>

<style lang="scss" scoped>
.head {
  background: url('~@/assets/background1.jpg');
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: relative;
  .breadcrumbs {
    float: right;
  }
  .form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 9.2593rem;
    border-radius: 0.1852rem;
    box-shadow: 0 0.037rem 0.2778rem rgba(0, 0, 0, 0.18);
    .title {
      height: 1.2963rem;
      line-height: 1.2963rem;
      text-align: center;
      font: {
        size: 0.463rem;
        weight: 700;
      }
      border-bottom: 0.0185rem solid #f0f0f0;
    }
    .entry {
      margin: 0.463rem auto;
      width: 7.4074rem;
      :deep(.ant-form) {
        .ant-btn {
          width: 2.7778rem;
        }
      }
    }
  }
}
.btn {
  margin-top: 0.1852rem;
  margin-right: 0.1852rem;
}
</style>
